import { request, gql } from "graphql-request";
import { useEffect, useState } from "react";
import env from "../env";
import { useWallet } from "../Wallet/useWallet";
import { resolveEnsBatch } from "./theGraph";

/*
* WE STRONGLY DISCOURAGE YOU TO USE BITQUERY
* This game was a small project that we made for fun as fast as we can.
* Our goal was to display all address linked to an address.
* For that we choosed to use BitQuery whitout any experience.
* The API of BitQuery is not stable, for the same request and variables some time it's works and some time it's return a random error.
* We choosed to keep it in order to not loose any time, but if you want to do something similar, we strongly discourage you to use BitQuery.
*/

const MAX_DEPTH = 2;

const formatData = (res, transactions, depth, ensResolver) => {
  for(const transaction of transactions) {
    if (transaction.sender.address == res.name && transaction.depth == depth) {
        const isAlreadyAdded = res.children && res.children.find(el => el.name == transaction.receiver.address);
        if (!isAlreadyAdded) {
          res.children.push({
              name: transaction.receiver.address,
              annotation: transaction.receiver.annotation,
              ens: ensResolver[transaction.receiver.address.toLowerCase()],
              children: []
          });
          if ((depth + 1) <= MAX_DEPTH) formatData(res.children[res.children.length - 1], transactions, depth + 1, ensResolver);
        }
    }
  }
}

const getEnsResolver = async (flows) => {
  const ensData = await resolveEnsBatch(flows.map(el => el.sender.address));
  const ensResolver = {};
  if (ensData) {
    for (const domain of ensData.domains) {
      if (domain.name.includes("]")) {
        ensResolver[domain.owner.id.toLowerCase()] =
          "(...)" + domain.name.split("]")[1];
      } else {
        ensResolver[domain.owner.id.toLowerCase()] = domain.name;
      }
    }
  }
  return ensResolver;
}

export type LinkedAddress = {
    name: string
    children: LinkedAddress[],
    ens: string,
    annotation: string
}

export const useBitquery = () => {
  const { activeAddress } = useWallet();
  const [bitQueryError, setBitQueryError] = useState(false);
  const [linkedAddress, setLinkedAddress] = useState(null);
  const [resBitquery, setResBitquery] = useState(null);
  const [successRequestNumber, setSuccessRequestNumber] = useState(0);
  const { signer } = useWallet();

  useEffect(() => {
    if (!resBitquery) return;

    let children = resBitquery.res.children;
    if (linkedAddress && linkedAddress.children) {
      children = children.concat(linkedAddress.children);
    }

    const formatedRes = {
      name: activeAddress,
      ens: null,
      annotation: null,
      children: children
    };
    setLinkedAddress(formatedRes);


    // Count the number of success in order to display loading at the end of the terminal
    setSuccessRequestNumber(current => current + 1);
  }, [resBitquery])

  const fetchData = async (address, from, till, index, attempt) => {
    try{
      const res = await request({
        url: env.bitQueryURL,
        document: gql`
          query (
            $network: EthereumNetwork!
            $address: String!
            $depth: Int!
            $limit: Int!
            $from: ISO8601DateTime
            $till: ISO8601DateTime
          ) {
            ethereum(network: $network) {
              outbound: coinpath(
                initialAddress: { is: $address }
                depth: { lteq: $depth }
                options: {
                  asc: "depth"
                  desc: "amount"
                  limitBy: { each: "depth", limit: $limit }
                }
                date: { since: $from, till: $till }
              ) {
                sender {
                  address
                  annotation
                }
                receiver {
                  address
                  annotation
                }
                amount
                currency {
                  symbol
                }
                depth
                count
              }
              inbound: coinpath(
                initialAddress: { is: $address }
                depth: { lteq: $depth }
                options: {
                  direction: inbound
                  asc: "depth"
                  desc: "amount"
                  limitBy: { each: "depth", limit: $limit }
                }
                date: { since: $from, till: $till }
              ) {
                sender {
                  address
                  annotation
                }
                receiver {
                  address
                  annotation
                }
                amount
                currency {
                  symbol
                }
                depth
                count
              }
            }
          }
        `,
        variables: {
          depth: 2,
          limit: 100,
          offset: 0,
          network: "ethereum",
          address: address,
          from: from,
          till: till,
          dateFormat: "%Y-%m",
        },
        requestHeaders: {
          "X-API-KEY": env.bitQueryKey,
        },
      });
      console.log("[Success] Fetching bitquery ", index, res);

      const inbounds = res.ethereum.inbound;
      const outbounds = res.ethereum.outbound;
      
      // In the terminal we do not the direction of the flow
      // Then, to use the same format function we invert the receiver and sender
      const inboundInvert = inbounds.map((tx) => {
        return {
            receiver: tx.sender,
            sender: tx.receiver,
            depth: tx.depth,
            amount: tx.amount,
            count: tx.count,
            currency: tx.currency
        }
      });
      const flows = outbounds.concat(inboundInvert);

      const ensResolver = await getEnsResolver(flows);

      const formatedRes = {
        name: address,
        ens: null,
        annotation: null,
        children: []
      };
      formatData(formatedRes, flows, 1, ensResolver);
      
      setResBitquery({
        res: formatedRes,
        index: index
      });

    } catch(e) {
      console.log("[Error] BitQuery fetching request number", index, "attempt ", attempt);
      setTimeout(() => {
        fetchData(address, from, till, index, attempt + 1);
      }, 10000);
    }
  }

  const updateLinkedAddress = async () => {
      const res = await signer.getTransactionCount();
      // If we see that the user have 0 transactions, we do not need to call bitquery
      if (res == 0) {
        // We simulate the case where we found no linked address with bitquery
        setSuccessRequestNumber(4);
        setLinkedAddress({
            name: activeAddress,
            ens: null,
            annotation: null,
            noChildren: true,
            children: []
          });
      } else {
        // The goal is to get the last 2 years interactions on the active address
        // With the advices of BitQuery we made 4 request of 6 months in order to not process to much data
        fetchData(activeAddress, "2021-06-02T00:00:00", "2022-02-01T23:59:59", 0, 0);
        fetchData(activeAddress, "2021-01-02T00:00:00", "2021-06-01T23:59:59", 1, 0);
        fetchData(activeAddress, "2020-06-02T00:00:00", "2021-01-01T23:59:59", 2, 0);
        fetchData(activeAddress, "2020-01-02T00:00:00", "2020-06-01T23:59:59", 3, 0);
      }
  }

  useEffect(() => {
    if (!activeAddress) return;
    try {
      updateLinkedAddress();
    } catch(e) {
      setBitQueryError(true);
      console.log("error", e);
    }
  }, [activeAddress]);

  return {
    bitQueryError,
    linkedAddress,
    successRequestNumber
  }
}
