type AsciiTitleProps = {
  fontSize?: number;
}

export default function AsciiTitle({ fontSize=14 }: AsciiTitleProps): JSX.Element {
  return <pre style={{color: "white", fontSize: fontSize}}>
            ███████╗██╗██╗  ██╗██╗    ██████╗ ██╗   ██╗███╗   ██╗<br/>
            ╚══███╔╝██║██║ ██╔╝██║    ██╔══██╗██║   ██║████╗  ██║<br/>
            {"  "}███╔╝ ██║█████╔╝ ██║    ██████╔╝██║   ██║██╔██╗ ██║<br/>
            ███╔╝   ██║██╔═██╗ ██║    ██╔══██╗██║   ██║██║╚██╗██║<br/>
            ███████╗██║██║  ██╗██║    ██║  ██║╚██████╔╝██║ ╚████║<br/>
            ╚══════╝╚═╝╚═╝  ╚═╝╚═╝    ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝<br/>
    </pre>;
}
