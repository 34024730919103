import React from "react";
import styled from "styled-components";
import LogoDiscordSvg from "../logos/logo-discord";
import LogoGithubSvg from "../logos/logo-github";
import LogoGuildSvg from "../logos/logo-guild";
import LogoSnapshotSvg from "../logos/logo-snapshot";
import LogoTwitterSvg from "../logos/logo-twitter";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: absolute;
  bottom: -35px;
  left: 0px;
`;

const TextLink = styled.a`
  cursor: pointer;
  font-size: 12px;
  color: white;
  font-weight: 600;

  margin: 0px;
  margin-left: 14px;

  :hover {
    width: fit-content;
    background: linear-gradient(-90deg, #c08aff, #a0f2e0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  @media (max-width: 900px) {
    margin-left: 0px;
    margin: 0px;
  }
`;

const LogoContainer = styled.a`
  margin-right: 14px;
  margin-top: 3px;
  cursor: pointer;

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin: 0px 10px;
    margin-top: 2px;
  }
`;

export default function BottomBar(): JSX.Element {
  const [hoverGithub, setHoverGithub] = React.useState(false);
  const [hoverTwitter, setHoverTwitter] = React.useState(false);
  const [hoverDiscord, setHoverDiscord] = React.useState(false);
  const [hoverSnapshot, setHoverSnapshot] = React.useState(false);
  const [hoverGuild, setHoverGuild] = React.useState(false);

  return (
    <Container>
        <LogoContainer
            href={"https://twitter.com/sismo_eth"}
            target="_blank"
            onMouseEnter={() => setHoverTwitter(true)}
            onMouseLeave={() => setHoverTwitter(false)}
        >
            {hoverTwitter ? (
            <LogoTwitterSvg gradient />
            ) : (
            <LogoTwitterSvg fill={"white"} />
            )}
        </LogoContainer>
        <LogoContainer
            href={"https://github.com/sismo-core"}
            target="_blank"
            onMouseEnter={() => setHoverGithub(true)}
            onMouseLeave={() => setHoverGithub(false)}
        >
            {hoverGithub ? (
            <LogoGithubSvg gradient />
            ) : (
            <LogoGithubSvg fill={"white"} />
            )}
        </LogoContainer>
        <LogoContainer
            onMouseEnter={() => setHoverDiscord(true)}
            onMouseLeave={() => setHoverDiscord(false)}
            href={"https://discord.gg/uAPtsfNrve"}
            target="_blank"
        >
            {hoverDiscord ? (
            <LogoDiscordSvg gradient />
            ) : (
            <LogoDiscordSvg fill={"white"} />
            )}
        </LogoContainer>
        <LogoContainer
            onMouseEnter={() => setHoverSnapshot(true)}
            onMouseLeave={() => setHoverSnapshot(false)}
            href={"https://snapshot.org/#/sismo.eth"}
            target="_blank"
        >
            {hoverSnapshot ? (
            <LogoSnapshotSvg gradient />
            ) : (
            <LogoSnapshotSvg fill={"white"} />
            )}
        </LogoContainer>
        <LogoContainer
            onMouseEnter={() => setHoverGuild(true)}
            onMouseLeave={() => setHoverGuild(false)}
            href={"https://alpha.guild.xyz/sismo-dao"}
            target="_blank"
        >
            {hoverGuild ? (
            <LogoGuildSvg gradient />
            ) : (
            <LogoGuildSvg fill={"white"} />
            )}
        </LogoContainer>

        <TextLink
            href={
            "https://blog.sismo.io/what-is-sismo-part-1-zk-badges-73e7031bacda"
            }
            target="_blank"
        >
            What is Sismo
        </TextLink>
        <TextLink
            href={
            "https://sismo.notion.site/Sismo-Is-Hiring-95d5ac373b5d4a6682cf9b9ff91fe526"
            }
            target="_blank"
        >
            Jobs
        </TextLink>
        <TextLink
            target="_blank"
            href={"https://blog.sismo.io"}
        >
            Blog
        </TextLink>
    </Container>
  );
}
