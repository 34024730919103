import styled from "styled-components";

type TextProps = {
  subtitle?: boolean;
  link?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  dark?: boolean;
  gradient?: boolean;
  button?: boolean;
  error?: boolean;
  success?: boolean;
};

const Text = styled.p<TextProps>`
  font-size: 14px;
  color: #4b5563;

  ${(props) =>
    props.subtitle &&
    `
    font-size: 11px;
  `}

  ${(props) =>
    props.link &&
    `
    font-family: ${props.theme.fonts.semibold};
    cursor: pointer;
  `}

  ${(props) =>
    props.button &&
    `
      font-family: ${props.theme.fonts.bold};
      font-size: 16px;
      @media (max-width: 800px) {
        font-size: 12px;
      }
  `}

  ${(props) =>
    props.h1 &&
    `
      font-size: 26px;
      font-family: ${props.theme.fonts.semibold};
      color: #13203D;

      @media (max-width: 1100px) {
        font-size: 18px;
      }
      @media (max-width: 800px) {
        font-size: 16px;
      }
    `}
    
    ${(props) =>
    props.h2 &&
    `
        font-size: 24px;
        font-family: ${props.theme.fonts.semibold};
        color: #13203D;

        @media (max-width: 1100px) {
          font-size: 24px;
        }
        @media (max-width: 800px) {
          font-size: 16px;
        }
    `}

    ${(props) =>
    props.h3 &&
    `
      font-family: ${props.theme.fonts.semibold};
      font-size: 22px;
      color: #13203D;
      line-height: 150%;
    `}

    ${(props) =>
    props.h4 &&
    `
      font-family: ${props.theme.fonts.semibold};
      font-size: 21px;
      color: #13203D;-
    `}

    ${(props) =>
    props.gradient &&
    `
        width: fit-content;
        background: linear-gradient(-90deg, ${props.theme.colors.gradient.start}, #A0F2E0);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `}

    ${(props) =>
    props.error &&
    `
        color: red;
        font-size: 14px;
      `}
    
    ${(props) =>
    props.success &&
    `
        color: green;
        font-size: 14px;
      `}

    ${(props) =>
    props.dark &&
    ` 
      color: white;
    `}
`;

export default Text;
