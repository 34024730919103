import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: 180px;
  width: 600px;
`;

const Disbaled = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

type GameProps = {
  disabled: boolean;
  onGameOver: () => void;
};

export default function Game({ disabled, onGameOver }: GameProps): JSX.Element {
  
  useEffect(() => {
    if (disabled) return;
    document.getElementById("iframe_game").focus();
  }, [disabled]);

  useEffect(() => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data === "gameover" || e.message === "gameover") {
        onGameOver();
      }
    });
  }, []);

  return (
    <Container>
      {disabled ? (
        <Disbaled />
      ) : (
        <iframe
          id="iframe_game"
          title="ziki-game"
          width="600"
          height="180"
          src="/runner/index.html"
          style={{
            border: "none",
          }}
        />
      )}
    </Container>
  );
}
