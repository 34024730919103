import styled from "styled-components";

const Container = styled.pre`
  @media (max-height: 700px) {
    display: none;
  }
`

type AsciiComingSoonProps = {
  fontSize?: number;
}

export default function AsciiComingSoon({ fontSize=6 }: AsciiComingSoonProps): JSX.Element {
  return <Container style={{color: "white", fontSize: fontSize}}>
        {" "}$$$$$$\  $$\                                         $$\                                                   $$\<br/>                                                               
        $$  __$$\ \__|                                        \__|                                                  \__|    <br/>                                                                
        $$ /  \__|$$\  $$$$$$$\ $$$$$$\$$$$\   $$$$$$\        $$\  $$$$$$$\        $$$$$$$\  $$$$$$\  $$$$$$\$$$$\  $$\ $$$$$$$\   $$$$$$\         $$$$$$$\  $$$$$$\   $$$$$$\  $$$$$$$\<br/>  
        \$$$$$$\  $$ |$$  _____|$$  _$$  _$$\ $$  __$$\       $$ |$$  _____|      $$  _____|$$  __$$\ $$  _$$  _$$\ $$ |$$  __$$\ $$  __$$\       $$  _____|$$  __$$\ $$  __$$\ $$  __$$\<br/>  
        {" "}\____$$\ $$ |\$$$$$$\  $$ / $$ / $$ |$$ /  $$ |      $$ |\$$$$$$\        $$ /      $$ /  $$ |$$ / $$ / $$ |$$ |$$ |  $$ |$$ /  $$ |      \$$$$$$\  $$ /  $$ |$$ /  $$ |$$ |  $$ |<br/>  
        $$\   $$ |$$ | \____$$\ $$ | $$ | $$ |$$ |  $$ |      $$ | \____$$\       $$ |      $$ |  $$ |$$ | $$ | $$ |$$ |$$ |  $$ |$$ |  $$ |       \____$$\ $$ |  $$ |$$ |  $$ |$$ |  $$ |<br/>  
        \$$$$$$  |$$ |$$$$$$$  |$$ | $$ | $$ |\$$$$$$  |      $$ |$$$$$$$  |      \$$$$$$$\ \$$$$$$  |$$ | $$ | $$ |$$ |$$ |  $$ |\$$$$$$$ |      $$$$$$$  |\$$$$$$  |\$$$$$$  |$$ |  $$ |<br/>  
        {" "}\______/ \__|\_______/ \__| \__| \__| \______/       \__|\_______/        \_______| \______/ \__| \__| \__|\__|\__|  \__| \____$$ |      \_______/  \______/  \______/ \__|  \__|<br/>  
        {"                                                                                                                          "}$$\   $$ |<br/>                                             
        {"                                                                                                                          "}\$$$$$$  |<br/>                                         
        {"                                                                                                                           "}\______/<br/>                                                                                                                                                                                    
    </Container>;
}
