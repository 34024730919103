import Onboard from "bnc-onboard";
import { API, Subscriptions } from "bnc-onboard/dist/src/interfaces";
import env from "../../env";

export function initOnboard(subscriptions: Subscriptions): API {
  return Onboard({
    dappId: env.blocknativeKey,
    networkId: 1,
    subscriptions,
    walletSelect: {
      heading: "Select a wallet provider",
      description: "",
      wallets: [
        { 
          walletName: "metamask" 
        },
        {
          walletName: 'walletConnect',
          infuraKey: env.infuraKey,
        },
      ],
    },
    walletCheck: [
      {
        checkName: "connect",
        description: "If you are on metamask mobile and stuck: kill tab, go to mainnet and refresh. Or use WalletConnect",
      },
    ],
  });
}
