import React, { FunctionComponent } from "react";
type FunctionComponentProps = {
  fill?: string;
  width?: number;
  height?: number;
  gradient?: boolean;
};

const LogoDiscordSvg: FunctionComponent<FunctionComponentProps> = (props) => {
  if (props.gradient)
    return (
      <svg
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M15.6216 1.29199C14.4454 0.744609 13.1842 0.341323 11.8655 0.110344C11.8415 0.105886 11.8175 0.117026 11.8051 0.139308C11.6429 0.431929 11.4632 0.813678 11.3374 1.11373C9.91903 0.898353 8.50794 0.898353 7.11866 1.11373C6.99281 0.807008 6.80661 0.431929 6.64368 0.139308C6.6313 0.11777 6.60731 0.10663 6.5833 0.110344C5.26531 0.340585 4.00406 0.743871 2.82717 1.29199C2.81698 1.29645 2.80825 1.30388 2.80245 1.31353C0.410121 4.93868 -0.245239 8.47472 0.0762589 11.9669C0.0777136 11.984 0.0871693 12.0004 0.100262 12.0107C1.67865 13.1864 3.2076 13.9002 4.70815 14.3733C4.73216 14.3807 4.75761 14.3718 4.77289 14.3517C5.12784 13.8601 5.44425 13.3417 5.71555 12.7965C5.73156 12.7646 5.71627 12.7267 5.68355 12.7141C5.18167 12.521 4.70378 12.2855 4.24408 12.0182C4.20772 11.9966 4.20481 11.9439 4.23826 11.9186C4.335 11.8451 4.43176 11.7686 4.52413 11.6914C4.54084 11.6773 4.56413 11.6743 4.58378 11.6832C7.6038 13.0817 10.8733 13.0817 13.8577 11.6832C13.8774 11.6735 13.9007 11.6765 13.9181 11.6906C14.0105 11.7679 14.1072 11.8451 14.2047 11.9186C14.2381 11.9439 14.236 11.9966 14.1996 12.0182C13.7399 12.2907 13.262 12.521 12.7594 12.7133C12.7267 12.726 12.7121 12.7646 12.7281 12.7965C13.0052 13.3409 13.3217 13.8593 13.6701 14.351C13.6846 14.3718 13.7108 14.3807 13.7348 14.3733C15.2426 13.9002 16.7716 13.1864 18.35 12.0107C18.3638 12.0004 18.3725 11.9847 18.374 11.9677C18.7587 7.93029 17.7295 4.42325 15.6456 1.31427C15.6405 1.30388 15.6318 1.29645 15.6216 1.29199ZM6.16654 9.84054C5.2573 9.84054 4.50812 8.99387 4.50812 7.95407C4.50812 6.91428 5.24277 6.06761 6.16654 6.06761C7.09755 6.06761 7.83948 6.92171 7.82493 7.95407C7.82493 8.99387 7.09027 9.84054 6.16654 9.84054ZM12.2982 9.84054C11.389 9.84054 10.6399 8.99387 10.6399 7.95407C10.6399 6.91428 11.3745 6.06761 12.2982 6.06761C13.2293 6.06761 13.9712 6.92171 13.9567 7.95407C13.9567 8.99387 13.2293 9.84054 12.2982 9.84054Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="18.4534"
            y1="7.24262"
            x2="-1.30859e-08"
            y2="7.24262"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08AFF" />
            <stop offset="1" stopColor="#A0F2E0" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="18.4534" height="14.4991" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill || "white"}
        d="M15.8692 1.54004C14.693 0.992656 13.4318 0.58937 12.113 0.358391C12.089 0.353933 12.065 0.365073 12.0527 0.387355C11.8904 0.679976 11.7108 1.06172 11.585 1.36178C10.1666 1.1464 8.7555 1.1464 7.36622 1.36178C7.24037 1.05506 7.05417 0.679976 6.89123 0.387355C6.87886 0.365817 6.85487 0.354676 6.83086 0.358391C5.51287 0.588632 4.25161 0.991918 3.07473 1.54004C3.06454 1.54449 3.05581 1.55193 3.05001 1.56158C0.65768 5.18672 0.00232005 8.72277 0.323817 12.215C0.325272 12.2321 0.334728 12.2484 0.347821 12.2588C1.92621 13.4345 3.45515 14.1482 4.9557 14.6213C4.97972 14.6288 5.00516 14.6199 5.02045 14.5998C5.3754 14.1081 5.69181 13.5897 5.9631 13.0446C5.97912 13.0126 5.96383 12.9748 5.93111 12.9621C5.42923 12.769 4.95134 12.5336 4.49164 12.2662C4.45528 12.2447 4.45237 12.1919 4.48582 12.1667C4.58255 12.0932 4.67932 12.0167 4.77169 11.9394C4.7884 11.9253 4.81169 11.9223 4.83134 11.9312C7.85136 13.3298 11.1209 13.3298 14.1053 11.9312C14.1249 11.9216 14.1482 11.9246 14.1657 11.9387C14.2581 12.0159 14.3548 12.0932 14.4523 12.1667C14.4857 12.1919 14.4835 12.2447 14.4472 12.2662C13.9875 12.5388 13.5096 12.769 13.007 12.9614C12.9742 12.974 12.9597 13.0126 12.9757 13.0446C13.2528 13.589 13.5692 14.1074 13.9176 14.5991C13.9322 14.6199 13.9584 14.6288 13.9824 14.6213C15.4902 14.1482 17.0191 13.4345 18.5975 12.2588C18.6113 12.2484 18.6201 12.2328 18.6215 12.2157C19.0063 8.17834 17.9771 4.67129 15.8932 1.56231C15.8881 1.55193 15.8794 1.54449 15.8692 1.54004ZM6.4141 10.0886C5.50486 10.0886 4.75568 9.24192 4.75568 8.20212C4.75568 7.16232 5.49033 6.31565 6.4141 6.31565C7.34511 6.31565 8.08704 7.16976 8.07249 8.20212C8.07249 9.24192 7.33783 10.0886 6.4141 10.0886ZM12.5458 10.0886C11.6366 10.0886 10.8874 9.24192 10.8874 8.20212C10.8874 7.16232 11.622 6.31565 12.5458 6.31565C13.4768 6.31565 14.2188 7.16976 14.2042 8.20212C14.2042 9.24192 13.4768 10.0886 12.5458 10.0886Z"
      />
    </svg>
  );
};

export default LogoDiscordSvg;
