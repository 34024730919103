import React, { FunctionComponent } from "react";

type FunctionComponentProps = {
  fill?: string;
  width?: number;
  height?: number;
  gradient?: boolean;
};

const LogoGithubSvg: FunctionComponent<FunctionComponentProps> = (props) => {
  if (props.gradient)
    return (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.06137 0C4.05748 0 0 4.15965 0 9.29201C0 13.3965 2.59624 16.8791 6.1978 18.1079C6.6509 18.1932 6.81628 17.9063 6.81628 17.6601C6.81628 17.4394 6.80834 16.8553 6.80381 16.08C4.28289 16.6416 3.75108 14.8344 3.75108 14.8344C3.33933 13.7612 2.74521 13.4755 2.74521 13.4755C1.92227 12.8989 2.8075 12.9104 2.8075 12.9104C3.71653 12.9761 4.19511 13.8681 4.19511 13.8681C5.00388 15.2879 6.31618 14.8779 6.83271 14.6398C6.91484 14.0394 7.14931 13.63 7.40814 13.3977C5.39582 13.1631 3.28042 12.3663 3.28042 8.80597C3.28042 7.79146 3.63328 6.96219 4.21324 6.31237C4.11979 6.07718 3.80886 5.13295 4.3016 3.85363C4.3016 3.85363 5.06279 3.60391 6.79363 4.80599C7.51688 4.59984 8.29169 4.49705 9.06252 4.49299C9.83222 4.49705 10.6076 4.59984 11.3314 4.80599C13.0611 3.60391 13.8206 3.85363 13.8206 3.85363C14.3151 5.13295 14.0041 6.07718 13.9107 6.31237C14.4918 6.96219 14.8423 7.79146 14.8423 8.80597C14.8423 12.375 12.7235 13.1607 10.705 13.3907C11.0301 13.6776 11.3201 14.2444 11.3201 15.1114C11.3201 16.3535 11.3087 17.3552 11.3087 17.6601C11.3087 17.9087 11.4719 18.1979 11.9318 18.1067C15.5299 16.8756 18.1239 13.3953 18.1239 9.29201C18.1239 4.15965 14.0664 0 9.06137 0Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="18.1239"
            y1="9.06193"
            x2="-1.28522e-08"
            y2="9.06193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08AFF" />
            <stop offset="1" stopColor="#A0F2E0" />
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill || "white"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1854 0.4375C4.1815 0.4375 0.124023 4.59715 0.124023 9.72951C0.124023 13.834 2.72027 17.3166 6.32183 18.5454C6.77492 18.6307 6.9403 18.3438 6.9403 18.0976C6.9403 17.8769 6.93237 17.2928 6.92784 16.5175C4.40692 17.0791 3.87511 15.2719 3.87511 15.2719C3.46336 14.1987 2.86923 13.913 2.86923 13.913C2.04629 13.3364 2.93152 13.3479 2.93152 13.3479C3.84055 13.4136 4.31913 14.3056 4.31913 14.3056C5.12791 15.7254 6.44021 15.3154 6.95674 15.0773C7.03886 14.4769 7.27333 14.0675 7.53217 13.8352C5.51985 13.6006 3.40444 12.8038 3.40444 9.24347C3.40444 8.22896 3.7573 7.39969 4.33727 6.74987C4.24381 6.51468 3.93288 5.57045 4.42562 4.29113C4.42562 4.29113 5.18682 4.04141 6.91765 5.24349C7.64091 5.03734 8.41572 4.93455 9.18655 4.93049C9.95625 4.93455 10.7316 5.03734 11.4554 5.24349C13.1851 4.04141 13.9446 4.29113 13.9446 4.29113C14.4391 5.57045 14.1281 6.51468 14.0347 6.74987C14.6158 7.39969 14.9664 8.22896 14.9664 9.24347C14.9664 12.8125 12.8476 13.5982 10.829 13.8282C11.1541 14.1151 11.4441 14.6819 11.4441 15.5489C11.4441 16.791 11.4328 17.7927 11.4328 18.0976C11.4328 18.3462 11.5959 18.6354 12.0558 18.5442C15.6539 17.3131 18.2479 13.8328 18.2479 9.72951C18.2479 4.59715 14.1904 0.4375 9.1854 0.4375Z"
      />
    </svg>
  );
};

export default LogoGithubSvg;
