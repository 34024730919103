import styled from "styled-components";

const Container = styled.button`
  border-bottom: 6px inset rgba(0, 0, 0, 0.5);
  border-left: 6px inset rgba(0, 0, 0, 0.5);
  border-right: 6px inset rgba(255, 255, 255, 0.5);
  border-top: 6px inset rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "VT323-Regular";
  font-size: 2.2rem;
  margin: 1rem;
  min-width: 200px;
  padding: 0.5rem;
  text-transform: uppercase;
  width: auto;

  background: #13203d;

  &:focus,
  &:hover {
    background: #172442;
  }
`;

const TextButton = styled.div`
  color: white;
`;

type ButtonProps = {
  text: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  loading?: boolean;
};

export default function Button({
  text,
  style,
  onClick,
}: ButtonProps): JSX.Element {
  return (
    <Container style={style} onClick={onClick}>
      <TextButton>{text}</TextButton>
    </Container>
  );
}
