import React, { FunctionComponent } from "react";

interface SVGDimension {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  style?: React.CSSProperties;
  gradient?: boolean;
}

const LogoGuildSvg: FunctionComponent<SVGDimension> = (props) => {
  if (props.gradient)
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2113:29726)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.72448 0.953125L10.2036 1.01078L10.2613 2.22151L11.3567 2.27917L11.299 3.37459L10.2036 3.43225L10.2613 4.64298L11.3567 4.70064L11.4143 8.33284L12.6251 8.39049L12.6827 9.60123L13.8935 9.65888L13.9511 10.8696L15.1618 10.9273L15.2195 12.0227L16.3149 11.965L16.3726 9.60123L17.5833 9.65888L17.5257 18.2493L11.3567 18.1917L11.299 12.0227L10.2036 11.965L10.1459 10.8696L7.66682 10.9273L7.60917 12.0227L6.51374 12.0804L6.45609 18.2493L0.287109 18.1917L0.344763 9.60123L1.5555 9.65888L1.61315 12.0227L2.70858 11.965L2.76623 10.8696L3.97697 10.812L4.03462 9.60123L5.24536 9.54357L5.30301 8.33284L6.51374 8.27519L6.5714 4.64298L7.66682 4.58533L7.60917 3.37459L6.51374 3.31694L6.5714 2.22151L7.66682 2.16386L7.72448 0.953125ZM2.76623 13.9829L3.97697 14.0406L3.91931 15.136L2.70858 15.0784L2.76623 13.9829ZM13.9511 13.9829L15.1618 14.0406L15.1042 15.136L13.8935 15.0784L13.9511 13.9829Z"
            fill="url(#paint0_linear_2113:29726)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2113:29726"
            x1="17.5833"
            y1="9.60122"
            x2="0.287109"
            y2="9.60122"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08AFF" />
            <stop offset="1" stopColor="#A0F2E0" />
          </linearGradient>
          <clipPath id="clip0_2113:29726">
            <rect
              width="17.2962"
              height="17.2962"
              fill="white"
              transform="translate(0.287109 0.953125)"
            />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43737 0L9.91649 0.057654L9.97415 1.26839L11.0696 1.32604L11.0119 2.42147L9.91649 2.47912L9.97415 3.68986L11.0696 3.74751L11.1272 7.37971L12.338 7.43737L12.3956 8.6481L13.6063 8.70576L13.664 9.91649L14.8747 9.97415L14.9324 11.0696L16.0278 11.0119L16.0855 8.6481L17.2962 8.70576L17.2386 17.2962L11.0696 17.2386L11.0119 11.0696L9.91649 11.0119L9.85884 9.91649L7.37971 9.97415L7.32206 11.0696L6.22663 11.1272L6.16898 17.2962L0 17.2386L0.057654 8.6481L1.26839 8.70576L1.32604 11.0696L2.42147 11.0119L2.47912 9.91649L3.68986 9.85884L3.74751 8.6481L4.95825 8.59045L5.0159 7.37971L6.22663 7.32206L6.28429 3.68986L7.37971 3.6322L7.32206 2.42147L6.22663 2.36381L6.28429 1.26839L7.37971 1.21073L7.43737 0ZM2.47912 13.0298L3.68986 13.0875L3.6322 14.1829L2.42147 14.1252L2.47912 13.0298ZM13.664 13.0298L14.8747 13.0875L14.8171 14.1829L13.6063 14.1252L13.664 13.0298Z"
        fill={props.fill || "#FBFBFB"}
      />
    </svg>
  );
};

export default LogoGuildSvg;
