import { Wallet } from "bnc-onboard/dist/src/interfaces";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { initOnboard } from "./initOnboard";

export type OnboardWallet = {
  walletConnected: boolean;
  connectWallet: (walletName?: string) => Promise<boolean>;
  activeAddress: string;
  signer: ethers.providers.JsonRpcSigner;
  activeEns: string;
};

export const useOnboard = (): OnboardWallet => {
  const [onboard, setOnboard] = useState(null);
  const [activeAddress, setActiveAddress] = useState(null);
  const [activeEns, setActiveEns] = useState(null);
  const [activeWallet, setActiveWallet] = useState(null);
  const [signer, setSigner] = useState(null);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (onboard) return;
    const subscriptions = {
      address: async (newAddress: string) => {
        setActiveAddress(newAddress);
      },
      wallet: (wallet: Wallet) => {
        setActiveWallet(wallet);
      },
    };
    setOnboard(initOnboard(subscriptions));
  }, []);

  useEffect(() => {
    const resolveENS = async () => {
      if (provider) {
        try {
          const ens = await provider.lookupAddress(activeAddress);
          if (ens) setActiveEns(ens);
          else setActiveEns(null);
        } catch(e) {
          setActiveEns(null);
          console.log("error:resolveEns", e);
        }
      } else {
        setActiveEns(null);
      }
    }
    resolveENS();
  }, [activeAddress])

  useEffect(() => {
    if (!activeWallet?.provider) {
      setSigner(null);
      setProvider(null);
      return;
    }
    const _provider = new ethers.providers.Web3Provider(activeWallet.provider);
    setProvider(_provider);
    setSigner(_provider.getSigner());
  }, [activeWallet]);

  const connectWallet = async (): Promise<boolean> => {
    try {
      const isWalletSelected = await onboard.walletSelect();
      if (isWalletSelected) return await onboard.walletCheck();
    } catch (error) {
      console.log(`error:onConnectWallet`, error);
    }
    return false;
  };

  return {
    activeAddress,
    walletConnected: Boolean(activeAddress),
    connectWallet,
    signer,
    activeEns
  };
};
