import React from "react";
import { useOnboard } from "./onboard/useOnboard";

export const ConnectorContext = React.createContext(null);

export default function WalletProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const onboard = useOnboard();

  return (
    <ConnectorContext.Provider value={onboard}>
      {children}
    </ConnectorContext.Provider>
  );
}
