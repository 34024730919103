import styled from "styled-components";
import { getMinimalAddress } from "../../utils/getMinimalAddress";
import { useWallet } from "../../Wallet/useWallet";
import Text from "../Text";

const Container = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
`;

export default function Account(): JSX.Element {
  const { activeAddress, activeEns } = useWallet();

  if (!activeAddress) return<></>;

  return (
    <Container>
    <Text style={{ color: "#C08AFF", fontWeight: 600}}>
        {activeEns}
    </Text>
      <Text style={{ color: "#C08AFF"}}>
        {getMinimalAddress(activeAddress)}
      </Text>
    </Container>
  );
}
