import { useEffect, useState } from "react";
import styled from "styled-components";
import { LinkedAddress } from "../../services/bitQuery";
import Account from "../Account";
import AsciiTitle from "../AsciiTitle";
import BottomBar from "../BottomBar";
import Button from "../Button";
import Terminal from "./components/Terminal";

const Container = styled.div<{isVisible: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #13203d;
  z-index: 100;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s linear;
`;

const Content = styled.div`
  position: relative;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  background-color: red;
  box-shadow: 0px 1px 32px #000613;
  border-radius: 5px;
  background-color: #13203d;
  
  padding: 35px 95px 20px 95px;
  max-height: calc(100vh - 100px);
`;

const Close = styled.div`
  position: absolute;
  color: white;
  left: 95px;
  top: 45px;
  cursor: pointer;
  font-weight: 600;
  @media (max-height: 950px) {
    top: 65px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  zoom: 0.8;
  @media (max-height: 750px) {
    display: none;
  }
`;

const DoxxPanel = styled.div` 
  position: absolute;
  right: -180px;
  bottom: 0px;
`

const SubtitleText = styled.div` 
  font-size: 18px;
  line-height: 150%;
  color: #FFFFFF;
  font-family: Inter-Regular;
  font-weight: 600;
  width: 100%;
`

const TextExplanation = styled.div` 
  font-size: 15px; 
  @media (max-height: 720px) {
    display: none;
  }
`

const TextCongratulation = styled.div` 
  font-size: 15px; 
`

const MainText = styled.div` 
  fisplay: flex;
  justify-content: flex-start;
`

type GameOverModalProps = {
  linkedAddress: LinkedAddress;
  onBack: () => void;
  isOpen: boolean;
  isFullLoaded: boolean;
};

export default function GameOverModal({
  linkedAddress,
  onBack,
  isOpen,
  isFullLoaded
}: GameOverModalProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen])

  return (
    <Container isVisible={isVisible}>
      <Content>
        <Account/>
        <DoxxPanel>
          <img src="/imgs/ziki-pixel-art.svg" style={{marginRight: 15}}/>
          <img src="/imgs/doxx-panel.svg" height="140"/>
        </DoxxPanel>
        <Close onClick={onBack}>{"< Back"}</Close>
        <AsciiTitle fontSize={11} />
        <SubtitleText style={{ marginTop: 25, marginBottom: 10, textAlign: "center"}}>
          Was playing this game worth 
          putting your privacy at risk?
        </SubtitleText>
        {linkedAddress && linkedAddress.children.length > 2 &&
          <p style={{width: "100%", marginTop: 10}}>
            Your address is linked to:
          </p>
        }
        <Terminal linkedAddress={linkedAddress} isFullLoaded={isFullLoaded}/>
        <MainText>
        {linkedAddress && (
          <>
            {linkedAddress.children.length < 3 ? (
              <>
                <TextCongratulation style={{ marginBottom: 20, width: "100%", fontWeight: 600, color: "#C08AFF" }}>
                  Congratulations! You played a Ziki Run from a relatively virgin address that ensured you limited privacy leaks. 
                </TextCongratulation>
                <TextExplanation style={{ marginBottom: 20 }}>
                  By doing so, you avoided sharing all your history just to play a browser game because you know analytical tools might infer a lot from your past interactions. 
                </TextExplanation>
                <TextExplanation style={{ marginBottom: 20, color: "#C08AFF" }}>
                  Come back soon to mint Sismo badges and keep controlling what you reveal about yourself.
                </TextExplanation>
              </>
            ) : (
              <>
                <TextCongratulation style={{ marginBottom: 20, width: "100%", fontWeight: 600, color: "#C08AFF" }}>
                  Be careful, connecting your wallet is sharing a lot more about 
                  you than you might have intended.
                </TextCongratulation>
                <TextExplanation style={{ marginBottom: 20 }}>
                  By doing so, you shared all your history, just to play a browser game. Analytical tools might infer a lot from your past interactions.
                </TextExplanation>
                <TextExplanation style={{ marginBottom: 20 }}>
                  Some of those interactions could be with your other private addresses, some could be with addresses owned by your relatives/friends and others might have leaked your financial history.
                </TextExplanation>
                <TextExplanation style={{ marginBottom: 20, color: "#C08AFF" }}>
                  Come back soon to mint Sismo badges and control what you reveal about yourself.
                </TextExplanation>
              </>
            )}
          </>
        )}
        </MainText>
        <Bottom style={{marginTop: 25}}>
          <div>
            <Button text="Join Discord" onClick={() => window.open("https://discord.gg/uAPtsfNrve")}/>
            <Button text="Join Twitter" onClick={() => window.open("https://twitter.com/sismo_eth")}/>
          </div>
        </Bottom>
        <BottomBar />
      </Content>
    </Container>
  );
}