import styled from "styled-components";
import { LinkedAddress } from "../../../services/bitQuery";

const Line = styled.div`
  font-size: 12px;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
  color: white;
  width: 580px;
  height: 170px;
  min-height: 170px;
  overflow-y: scroll;
  padding: 10px;
`;

const Picto = styled.span`
    font-family: -webkit-pictograph;
`

type TerminalProps = {
  linkedAddress: LinkedAddress;
  isFullLoaded: boolean;
};

export default function Terminal({
  linkedAddress,
  isFullLoaded
}: TerminalProps): JSX.Element {

    const Picto1 = <Picto>
        ├── 
    </Picto>

    const Picto2 = <Picto>
        │  └── 
    </Picto>

    const Picto3 = <Picto>
        │  ├── 
    </Picto>

    const Picto4 = <Picto>
        └── 
    </Picto>

    const Picto5 = <Picto style={{marginLeft: 25}}>
        └── 
    </Picto>

    const Picto6 = <Picto style={{marginLeft: 25}}>
        ├── 
    </Picto>

  return (
        <Data style={{ marginTop: 10, marginBottom: 30 }}>
          {linkedAddress &&
            <>
                {linkedAddress.children.length == 0 ? (
                    <>No interactions found on your address...</>
                ) : (
                    <>
                    <Line>
                    {linkedAddress.name}
                </Line>
                {linkedAddress && linkedAddress.children.map((el, index) => <span key={el.name + "parent" + index}>
                    <Line>
                        {(linkedAddress.children.length - 1) == index ? Picto4 : Picto1} <b>{el.name}</b> {el.ens} {el.annotation}
                    </Line>
                    <>
                    {
                        el.children && el.children.map((children, indexChild) => <Line key={children.name + "child" + indexChild}>
                            {
                                (linkedAddress.children.length - 1) == index ?
                                <>
                                    {(el.children.length - 1) == indexChild ? 
                                        Picto5 
                                        : 
                                        Picto6
                                    } 
                                </>
                                :
                                <>
                                    {(el.children.length - 1) == indexChild ? 
                                        Picto2 
                                        : 
                                        Picto3
                                    } 
                                </>
                            }
                            <b>{children.name}</b> {children.ens} {children.annotation}
                        </Line>)
                    }
                    </>
                </span>
                )}
          {
              !isFullLoaded && 
              <Line>
                  Loading...
              </Line>
          }
            </>
                )}
                </>
    }
        </Data>
  );
}