import { request, gql } from "graphql-request";

type EnsResolve = {
  domains: {
    id: string,
    name: string,
    owner: {
      id: string;
    }
  }[]
}

export const resolveEnsBatch = async (address: string[]): Promise<EnsResolve> => {
  return await request({
      url: "https://api.thegraph.com/subgraphs/name/ensdomains/ens",
      document: gql`
        query ($address: [ID!]) {
          domains(where: { owner_in: $address }) {
            id
            name
            owner {
              id
            }
          }
        }
      `,
      variables: {
        address: address,
      },
    });
};
