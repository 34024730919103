import "./App.css";

import WalletProvider from "./Wallet/WalletProvider";
import ZikiGame from "./components/ZikiGame";

function App(): JSX.Element {
  return (
    <WalletProvider>
      <ZikiGame />
    </WalletProvider>
  );
}

export default App;
