import React, { FunctionComponent } from "react";

interface SVGDimension {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
  style?: React.CSSProperties;
  gradient?: boolean;
}

const LogoSnapshotSvg: FunctionComponent<SVGDimension> = (props) => {
  if (props.gradient)
    return (
      <svg
        width="15"
        height="18"
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.715098 9.63237L11.8285 0.0121772C11.8285 0.0121772 12.7157 -0.13367 12.4524 0.584127C12.1891 1.30192 8.92351 7.57049 8.92351 7.57049L15.1313 7.53618C15.1313 7.53618 15.7295 7.60481 15.4519 8.2168C15.1743 8.82878 4.12096 18 4.12096 18C4.12096 18 3.38541 18 3.56858 17.4509C3.75175 16.9019 7.12899 10.4903 7.12899 10.4903L0.863926 10.4302C0.863926 10.4302 0.506167 10.3187 0.526202 10.0413C0.528349 9.96393 0.546198 9.88778 0.578667 9.81748C0.611137 9.74719 0.657551 9.68421 0.715098 9.63237Z"
          fill="url(#paint0_linear_2113:29730)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2113:29730"
            x1="15.5254"
            y1="8.99999"
            x2="0.525391"
            y2="8.99999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08AFF" />
            <stop offset="1" stopColor="#A0F2E0" />
          </linearGradient>
        </defs>
      </svg>
    );

  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.18844 9.57577L11.2276 0.0121057C11.2276 0.0121057 12.1089 -0.132884 11.8474 0.580694C11.5858 1.29427 8.342 7.52601 8.342 7.52601L14.5083 7.49189C14.5083 7.49189 15.1025 7.56012 14.8268 8.16851C14.551 8.7769 3.57154 17.8942 3.57154 17.8942C3.57154 17.8942 2.84091 17.8942 3.02286 17.3484C3.2048 16.8025 6.55948 10.4287 6.55948 10.4287L0.336273 10.3689C0.336273 10.3689 -0.0190949 10.2581 0.000805678 9.98231C0.00293817 9.90537 0.0206679 9.82967 0.0529206 9.75979C0.0851734 9.68991 0.131278 9.62731 0.18844 9.57577Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};

export default LogoSnapshotSvg;
