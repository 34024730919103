import React, { FunctionComponent } from "react";

type FunctionComponentProps = {
  fill?: string;
  width?: number;
  height?: number;
  gradient?: boolean;
};

const LogoTwitterSvg: FunctionComponent<FunctionComponentProps> = (props) => {
  if (props.gradient)
    return (
      <svg
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1239 1.76045C17.458 2.05905 16.741 2.26098 15.9889 2.3512C16.7569 1.88719 17.3463 1.15144 17.6239 0.274972C16.9048 0.704611 16.1101 1.01717 15.2612 1.18473C14.5847 0.455416 13.6177 0 12.5475 0C10.4954 0 8.83057 1.68096 8.83057 3.75397C8.83057 4.04827 8.86249 4.33399 8.92631 4.60896C5.83599 4.45214 3.09671 2.95914 1.26166 0.685274C0.941459 1.24166 0.758481 1.88719 0.758481 2.57461C0.758481 3.87642 1.41485 5.02571 2.41269 5.69917C1.8042 5.68091 1.22975 5.51013 0.727634 5.23086V5.27704C0.727634 7.09657 2.00951 8.61427 3.71158 8.95798C3.39989 9.04605 3.07118 9.09009 2.73183 9.09009C2.49247 9.09009 2.25844 9.06754 2.03185 9.0235C2.50524 10.5143 3.87753 11.6003 5.50514 11.6293C4.23284 12.6368 2.62864 13.2372 0.887203 13.2372C0.587212 13.2372 0.290416 13.22 0 13.1856C1.64569 14.249 3.60095 14.8709 5.69982 14.8709C12.54 14.8709 16.2793 9.15132 16.2793 4.19006C16.2793 4.02679 16.2761 3.86353 16.2697 3.70349C16.9963 3.17396 17.6271 2.51339 18.1239 1.76045Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="18.1239"
            y1="7.43543"
            x2="-1.28522e-08"
            y2="7.43543"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C08AFF" />
            <stop offset="1" stopColor="#A0F2E0" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="18.1239" height="14.8709" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

  return (
    <svg
      height="15"
      viewBox="0 0 19 15"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "19"}
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          fill={props.fill || "white"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1239 1.8249C17.458 2.1235 16.741 2.32543 15.9889 2.41566C16.7569 1.95165 17.3463 1.21589 17.6239 0.339425C16.9048 0.769064 16.1101 1.08162 15.2612 1.24918C14.5847 0.519869 13.6177 0.0644531 12.5475 0.0644531C10.4954 0.0644531 8.83057 1.74542 8.83057 3.81842C8.83057 4.11273 8.86249 4.39844 8.92631 4.67341C5.83599 4.51659 3.09671 3.02359 1.26166 0.749727C0.941459 1.30611 0.758481 1.95164 0.758481 2.63907C0.758481 3.94087 1.41485 5.09016 2.41269 5.76362C1.8042 5.74536 1.22975 5.57458 0.727634 5.29531V5.3415C0.727634 7.16102 2.00951 8.67872 3.71158 9.02243C3.39989 9.11051 3.07118 9.15454 2.73183 9.15454C2.49247 9.15454 2.25844 9.13199 2.03185 9.08795C2.50524 10.5788 3.87753 11.6647 5.50514 11.6937C4.23284 12.7012 2.62864 13.3016 0.887203 13.3016C0.587212 13.3016 0.290416 13.2845 0 13.2501C1.64569 14.3134 3.60095 14.9353 5.69982 14.9353C12.54 14.9353 16.2793 9.21577 16.2793 4.25451C16.2793 4.09125 16.2761 3.92798 16.2697 3.76794C16.9963 3.23841 17.6271 2.57785 18.1239 1.8249Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            fill={props.fill || "white"}
            width="18.1239"
            height="14.8709"
            transform="translate(0 0.0644531)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoTwitterSvg;
