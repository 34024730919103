import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { useBitquery } from "../../services/bitQuery";
import Runner from "../Runner";
import AsciiTitle from "../AsciiTitle";
import { useWallet } from "../../Wallet/useWallet";
import GameOverModal from "../GameOverModal";
import BottomBar from "../BottomBar";
import AsciiComingSoon from "../AsciiComingSoon";
import Account from "../Account";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #0E182D;
  background-image: url("imgs/background.svg");

  @media (max-height: 600px) {
    display: none;
  }
  @media (max-width: 720px) {
    display: none;
  }
`;

const MobileError = styled.div`
  background-color: #13203d;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  @media (min-height: 601px) and (min-width: 721px) {
    display: none;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 600px;
  box-shadow: 0px 1px 32px #000613;
  border-radius: 5px;
  background-color: #13203d;
  padding: 105px 95px 120px 95px;
  max-height: calc(100vh - 150px);
  @media (max-height: 940px) {
    padding: 55px 75px 50px 75px;
  }
`;

const RunnerContainer = styled.div` 
  position: relative;
  display: flex;
  justify-content: center;
`

const ErrorText = styled.div` 
  margin-top: 20px;
  color: #BBBBBB;
  @media (max-height: 550px) {
    display: none;
  }
`

export default function ZikiGame(): JSX.Element {
  const { connectWallet, walletConnected } = useWallet();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { linkedAddress, successRequestNumber } = useBitquery();
  const [isGameOver, setIsGameOver] = useState(false);

  useEffect(() => {
    if (!isGameOver) return;
    if (linkedAddress && (linkedAddress.children.length > 0 || linkedAddress.noChildren)) {
      setModalIsOpen(true);
    }
    setIsGameOver(false);
  }, [isGameOver]);

  return (
    <>
      {modalIsOpen && (
        <GameOverModal
          isOpen={modalIsOpen}
          linkedAddress={linkedAddress}
          onBack={() => setModalIsOpen(false)}
          isFullLoaded={successRequestNumber >= 4}
        />
      )}
      <Container>
          <Content>
            <Account />
            <AsciiTitle />
            <RunnerContainer style={{ marginTop: 20}}>
              {!walletConnected && (
                <Button
                  text="Connect wallet to play"
                  onClick={connectWallet}
                  style={{
                    position: "absolute",
                    top: 40,
                    zIndex: 15,
                    width: 370,
                  }}
                />
              )}
              <Runner
                disabled={!walletConnected}
                onGameOver={() => setIsGameOver(true)}
              />
            </RunnerContainer>
            <ErrorText>
              <AsciiComingSoon />
              <p style={{ marginTop: 15 }}>Try :</p>
              <ul>
                <li>
                  Getting a high score above by bringing your Ziki to the Private
                  Reputation Promised Land.
                </li>
                <li>
                  Joining Sismo DAO Discord & share with the community what badge
                  types you are expecting and how you would like to use them.
                </li>
                <li>Following Sismo on Twitter</li>
                <li>Checking our FAQ</li>
              </ul>
              <p>ERROR_SISMO_IS_STILL_BEING_BUILT</p>
            </ErrorText>
            <BottomBar />
          </Content>
      </Container>
      <MobileError>
        <AsciiTitle fontSize={9} />
        <p style={{ marginTop: 15 }}>
          Sorry Ziki Run is not available on mobile...
        </p>
      </MobileError>
    </>
  );
}
